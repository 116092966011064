import React from 'react'
const handleHideSideBar =()=>{
    const button = document.getElementById('offcanvasMenu');
    button.classList.remove('show');
    console.log(button,'button');
};
const handleShowSideBar =()=>{
    const button = document.getElementById('offcanvasMenu');
    button.classList.add('show');
    console.log(button,'button');
}
const Header = () => {
    return (
        <>
            <header class="header_section header_transparent sticky-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="main_header d-flex justify-content-between align-items-center">
                                <div class="header_logo">
                                    <a class="sticky_none" href="/"><img aria-label="logo" width="142" src="assets/img/logo/logo.png" alt="" /></a>
                                </div>

                                <div class="header_right_sidebar d-flex align-items-center">
                                    <div class="main_menu d-none d-lg-block pe-0">
                                        <nav>
                                            <ul class="d-flex">
                                                <li><a href="/">Home</a></li>
                                                <li><a href="/#download">Download</a></li>
                                                <li><a href="/#aboutus">About Us</a></li>
                                                <li><a href="/#howtoplay">How to Play</a></li>
                                                <li><a href="/#screenshots">Screenshots</a></li>
                                                <li><a href="/#faq">Faq</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div class="canvas_open">
                                        <button type="button" onClick={handleShowSideBar}><i class="icofont-navigation-menu" ></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenu" >
                <div class="offcanvas-header justify-content-end">
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <ul class="offcanvas_main_menu">
                        <li class="menu-item-has-children active" onClick={handleHideSideBar}>
                            <a href="#home" >Home</a>
                        </li>
                        <li class="menu-item-has-children"onClick={handleHideSideBar}><a href="#download">Download</a></li>
                        <li class="menu-item-has-children"onClick={handleHideSideBar}><a href="#aboutus">About Us</a></li>
                        <li class="menu-item-has-children"onClick={handleHideSideBar}><a href="#howtoplay">How to Play</a></li>
                        <li class="menu-item-has-children"onClick={handleHideSideBar}><a href="#screenshots">Screenshots</a></li>
                        <li class="menu-item-has-children"onClick={handleHideSideBar}><a href="#faq">Faq</a></li>
                    </ul>

                    <a class="btn btn-link wow fadeInUp dn_btn" data-wow-delay="0.3s" data-wow-duration="1.3s" href="all-game.html">Download App Now</a>

                </div>
            </div>
        </>

    )
}

export default Header
