import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LandingPage from '../UIPages/LandingPage'
import Header from '../UIPages/Header'
import Footer from '../UIPages/Footer'
import PrivacyPolicy from '../UIPages/PrivacyPolicy'
import RefundAndCanc from '../UIPages/RefundAndCanc'
import TermsAndCond from '../UIPages/TermsAndCond'
import TdsPolicy from '../UIPages/TdsPolicy';

const Routing = () => {
    return (
        <>
            <Router>
                <Header />
                <Routes>
                    <Route exact path="/" element={<LandingPage />}></Route>
                    <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
                    <Route exact path="/RefundAndCanc" element={<RefundAndCanc />}></Route>
                    <Route exact path="/TermsAndCond" element={<TermsAndCond />}></Route>
                    <Route exact path="/TdsPolicy" element={<TdsPolicy />}></Route>


                </Routes>
                <Footer />

            </Router>

        </>
    )
}

export default Routing
