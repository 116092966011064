import React, { useEffect } from 'react'

const RefundAndCanc = () => {

    // ********* Scroll Upside ********** //
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <div class="breadcrumbs_aree breadcrumbs_bg" data-bgimg="assets/img/bg/main_bg.png">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumbs_text text-center">
                                <h2>Terms and Conditions </h2>
                                <ul class="d-flex justify-content-center">
                                    <li><a href="/">Home </a></li>
                                    <li> <span>//</span></li>
                                    <li> Terms and conditions</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page_wrapper">


                <div class="container mb-5" >
                    <div class=" align-items justify-content-between">

                        <p> These terms and conditions of use (“Terms of Use”) along with other policies available on our platform forms a legally binding agreement between You and Bunzzi (“Bunzzi”, “Our”, “We”, “Us”).</p>

                        <p>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE PLAYING ANY MOBILE GAMES OR/AND USING THE Bunzzi PLATFORM.
                            If You do not agree with any part of these Terms of Use or/and Privacy Policy, kindly refrain from playing any Mobile Games or otherwise using Our services on the Platfo</p>

                        <p>You may only use Our web portal Bunzzi websites, mobile applications (collectively, the “Platform”) if You agree to abide by the terms and conditions as laid down in this Agreement. To better understand the scope of these Terms of Use, how they may be updated and the meaning of the various words used in these Terms of Use please refer to the Introduction section of these Terms of Use.
                        </p>
                        <p>Only people of sound mind who are above 18 years of age may use this Platform.

                        </p><p>This Platform may not be used by residents of Assam, Odisha, Nagaland, Sikkim and Telangana. Further, there may be certain games that may be restricted in some additional states.</p>
                        You may register on the Platform and add cash to Your account by following the registration procedure laid down in the section on Nature and use of the Platform..
                        The rules governing Your participation in the Contests available on Our Platform are available at Our section on Participation. Further, each Mobile Game may be subject to its own Game Rules and Code of Conduct.
                        Please note that We have the sole discretion to determine the terms applicable on any Contest including the Awards to be given in any Contest. Further, the Entry Fee for any contest is non-refundable and may only be refunded under the circumstances stated in Our Return, Refund and Cancellation policy.

                        <p>Winnings can be withdrawn by You from Your account and will be credited within 72 hrs of withdrawal request.
                            We further request that You follow the precautions laid down in Our Safety section while playing Mobile Games on Our Platform.
                            You may not use the Platform in ways stated in Our Restrictions section. You will indemnify Us in the event We suffer any losses on account of Your use of Our Platform or Your failure to abide by this Agreement.

                        </p><p>Your use of this Platform is completely at Your own risk. We disclaim all warranties in relation to the Platform. Any dispute between You and Us will be resolved by way of Arbitration which will be conducted in Udaipur Jurisdiction.1.1. Bunzzi is a Proprietorship Firm. Bunzzi operates a mobile gaming portal in India, which offers skill and learning based digital games developed by Third-Party Game Developers and other promotional and interactive features (collectively “Services”) through its web portal Bunzzi websites, mobile applications (collectively, the “Platform”).</p>
                        <p>1.2 . Capitalized terms used herein but not defined shall have the meanings ascribed to them under section </p>
                        <p>1.9 (Definitions and Interpretation) of this Agreement.1.3 . This Agreement shall apply to all Persons who visit the Platform, or create a player Account including any contributors of content over the Platform (the “Users”, “You” or “Your”).</p>
                        <p>1.4 . By registering on, browsing, downloading, using or accessing any part of the Platform or utilizing the Services, the Users agree to be bound by this Agreement.</p>
                        <p>1.5 . If at any time the User does not accept or agree to any terms of this Agreement, on a continuing basis in relation to any of the Services offered on the Platform, they should not access the Platform.</p>
                        <p>1.6 . Bunzzi reserves the right to periodically review, update, change or replace any part of these Terms of Use or other terms of the Agreement as it may consider appropriate at its sole and absolute discretion</p>
                        <p>1.7 . Any changes to these Terms of Use will be posted on this page wherever appropriate. The Users must visit this page periodically for updates on the Terms of Use applicable to their use of the Platform.</p>
                        <p>1.8 . Any Users’ continued usage of the Platform after any such update, change, replacement or modification in the Agreement constitutes their valid consent to such changes.<br />

                        </p><h4>1.9 . Definitions</h4>

                        <p>&gt;1.9.1 . “Agreement” shall mean these Terms of Use, the Privacy Policy and any other rules or policies available on the Platform including all other additional or modified terms and conditions and policies in relation to the Platform or any current or future services that may be offered by Bunzzi. This Agreement is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediary Guidelines) Rules, 2011. This Agreement is an electronic record generated by a computer system and does not require any physical or digital signatures.</p>

                        <p>&gt;1.9.2 . “Applicable Law” means all laws, statutes, common law, regulations, ordinances, codes, rules, guidelines, orders, permits, tariffs and approvals, including those relating to health and safety, of any governmental authority or self-regulatory guideline that may apply to the Users or is related to the subject matter of this Agreement as applicable in the territory of India.</p>

                        <p>1.9.3 . “Contest” is a skill based competition offered by Bunzzi on the Platform in which Users compete either in a tournament or 1v1 battle by playing the Mobile Games on the Platform. Contests can be either ‘paid’ or ‘free to play’. Paid Contests require the Users to pay an Entry Fee for participation, whereas free to play Contests are open to all Users without ant Entry-Fee.</p>

                        <p>1.9.4 . “Balance” is the credit (either in the form of cash or Bunzzi Tokens) available in an Account and includes amount added by a User through Deposits or/and earned through Winnings by participating in Contests on the Platform.</p>

                        <p>1.9.5 . “Deposit” is the amount added by the User to his/her Account through his/her preferred payment mode (e-wallet, debit/credit cards, UPI/BHIM, net banking). The Deposit conversion ratio is INR. 1/- (Rupees One Only) = INR 1 (one).</p>

                        <p>1.9.6 . “Entry-Fee” refers to the fee which Users pay either in the form of cash Balance available in their Account to participate in a Contest on the Platform.</p>

                        <p>1.9.7 . “Mobile Games” refers to the digital games of skill including fantasy games available on the Platform developed by Bunzzi or Third-Party Game Developers.</p>

                        <p>1.9.8 . “Person” shall mean any natural person, limited or unlimited liability company, corporation, partnership (whether limited or unlimited), proprietorship, Hindu undivided family, trust, union, association, government or any agency or political subdivision thereof or any other entity that may be treated as a person under the Applicable Law.</p>

                        <p>1.9.9 . “Third Party Game Developer” refers to any Person (natural or juristic) whose Mobile Game is available on the Platform.</p>

                        <p>1.9.10 “Winnings” are awards (in the form of cash) given to a User for winning or/and performing well in a particular Contest on the Platform.</p>

                        <h6>1.10 . Interpretation</h6>

                        <p>1.10.1 . Any reference to the singular includes a reference to the plural and vice versa, and any reference to one gender includes a reference to other gender(s), unless explicitly provided for.</p>

                        <p>1.10.2 . Headings and captions are used for convenience only and not for interpretation of the Agreement.</p>

                        <p>1.10.3 . Any reference to a natural Person shall, include his/her heirs, executors and permitted assignees and any reference to a juristic Person shall, include its affiliates, successors and permitted assignees, unless repugnant to the context.
                            Eligibility</p>

                        <p>2.1 . Any browsing, registering, downloading, using or accessing by any Person of any part of the Platform or utilization of any of the services offered by Bunzzi will be deemed to constitute a legal contract between the User and Bunzzi.</p>

                        <p>2.2 . A User may use the Platform if (a) it is a natural Person, as per any Applicable Law, regulation or generally accepted practices or guidelines applicable in the country of which User is a citizen, in which User is a resident or from where User uses the Platform; or (b) it is a juristic Person, as per any Applicable Law, regulation or generally accepted industry practices and guidelines applicable in the country where User is registered, where User maintains its principal office or from where User uses the Platform</p>

                        <p>2.3 . Any User who is “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. is not eligible to enter into this Agreement with Bunzzi.</p>

                        <p>2.4 . The Platform should not be used, installed, or played by any Person under the age of 18 years.</p>

                        <p>2.5 . No Person, whether an Bunzzi employee or not, who is involved in the development of Mobile Games or/and algorithms on the Platform can participate in any Mobile Games on the Platform, where such participation could alter, modify or unduly influence his/her performance on the Mobile Game, without express written permission of Bunzzi and subject to such te rms and conditions as Bunzzi may seek to impose on such Person.</p>

                        <p>2.6 . The Mobile Games and the services available on the Platform are open only to Persons currently residing in India. Persons residing in the state of Assam, Odisha, Nagaland, Sikkim and Telangana (“Restricted States”) are prohibited from participating in any Mobile Games on the Platform. Further, there may be certain games that may be restricted in some additional states. Please check the Special Rules/Terms of each Game to know more.</p>

                        <p>2.7 . Only those Users who have successfully registered on the Platform in the manner prescribed in section 3 (Nature and Use of the Platform), and who utilize the services of the Platform in accordance with the Agreement including these Terms of Use, shall be eligible to use the Platform.</p>
                        <p>2.8 . Certain services being provided on the Platform may be subject to additional conditions or rules applicable to a particular Mobile Game or Contest in that respect (“Game Terms”). Such Game Terms are deemed to be incorporated herein by reference. Users must agree to these Game Terms before playing any such Mobile Game or Contest on the Platform. To the extent that these Terms of Use are inconsistent with Game Terms, the Game Terms shall prevail.</p>
                        <p>2.9 . Bunzzi reserves the right to terminate any User’s Account and/or deny access to the Platform if it is brought to Bunzzi’s notice that a particular User has violated the Agreement including any of these Terms of Use.</p>
                        Nature and use of the Platform<p></p>
                        <p>3.1 . Bunzzi provides the Platform to You on which You can play Mobile Games on independent Third Party Game Developers and earn Money. These Third Party Application can mutually decided by the participants on their own that which app they have to use to play the Ludo Game, we only provide contest wherein Players can coordinate with their Opponents. These Mobile Games have to be separately downloaded or installed.</p>
                        <p>3.2 .Registration You may register by approving and agreeing to this Agreement and through your gmail Account on the Platform, an account will be created for You (“Account”).</p>
                        <p>3.3 . By registering for an Account, You represent, warrant and agree that:</p>
                        a) You fulfil the eligibility criteria as detailed in section 2 (Eligibility);
                        b) You will provide only true, accurate, current, and complete information about Yourself (“User Details”);
                        c) You will maintain and promptly update the User Details and other information that You provide on the Platform to keep it true, accurate, current, and complete.3.4 Privacy
                        a) Our privacy policy available at https://bunzzi.in/privacy/ (“Privacy Policy”) explains how We treat Your personal data and protect Your privacy when You use the Platform and our Services. By using the Platform, You agree that We can use Your data and other information according to the Privacy Policy.
                        b) You are responsible for maintaining the confidentiality of passwords associated with any device You use to access the Platform. Accordingly, You are solely responsible for all activities that occur with Your device. If You become aware of any unauthorized use of Your device, You are required to notify the relevant authorities as soon as possible.

                        <h6>3.5 Deposits</h6>

                        a) In order to participate in certain Contests, the Users may be required to pay an Entry Fee in the form of cash. Users may add cash to their Account by using any of the payment modes available on the Platform.<p>
                            b) The Deposits may be used to participate in Contests in the manner provided under section 4 (Participation in Contests).</p><p>
                            3.6 .Each User is solely responsible for all activities that occur under their Account, including the use of any of the Services on the Platform.</p><p>
                            3.7 . Bunzzi grants such Users a non-transferrable, limited, non-exclusive and revocable privilege to register an Account on and use the Platform.</p><p>
                            3.8 . If Bunzzi identifies that the same User Details are being used under multiple accounts, then Bunzzi at its sole discretion will terminate/deactivate the Account without the Users’ consent and any Deposit in the Account will not be refunded.</p><p>
                            3.9 . Bunzzi, at its sole and absolute discretion and without any notice to the Users, reserves the right to restrict, suspend or terminate any Account or access to the Platform temporarily or permanently to any User.</p><p>
                            3.10 . Bunzzi may, at its sole and absolute discretion restrict, change, suspend, or terminate any User’s access to all or any part of the Platform, including but not limited to:</p><p>
                            a) reject, move, or remove any material that may be submitted by the User;
                            b) move or remove any content that is available on the Platform;
                            c) deactivate or delete an Account and all related information and files on their Account;
                            d) establish general practices and limits concerning use of the Platform and the services; and
                            e) assign its rights and liabilities with respect to all Accounts hereunder, to any entity.

                            3.11 . Notwithstanding anything to the contrary herein, the Users acknowledge and agree that they shall have no ownership or other interest in their Account, and further acknowledge and agree that all rights in and to their Account are and shall forever be owned by and inure to the benefit of Bunzzi.</p>

                        <h6>3.12. Platform Fee Information</h6>
                        <b style={{ fontWeight: '600', fontSize: '15px' }}>NOTE:- Plateform Fess (commission) and withdrawal Fees Charged From Players are inclusive of GST</b><br />
                        a) The actual house edge for all the products/games is between 0% and 30% and under extreme exceptional cases may go beyond this range.
                        Tournaments will get cancelled and all entry fees refunded if the minimum number of users required did not join the tournament
                        Cash Prizes are distributed to the winners based on their rank.<p></p>

                        Bunzzi Platform fee for such registrations is set currently at flat 20% of the entry fee i.e. 2 Rs platform fees on Contest of Rs. 10.<p></p>

                        Bunzzi Platform fee is subject to change as per Bunzzi policy without prior notice.<p></p>

                        <h6>Participation in contest</h6>

                        4.1 . The Users may use the Platform to participate in a variety of Contests available on the Platform.
                        4.2 . In order to participate in a Contest, each User may be required to pay an Entry Fee to the Platform which is deducted from the Balance available in the Account of the User.<p></p>

                        4.3 . Each Contest has a pre-determined limit on the number of Users that can participate and a specified time before which entries must be made. A Contest is deemed to be a valid Contest on the Platform, only if the requisite number of Users register for the Contest before the prescribed entry time and the refund will be settled in the deposited amount.4.4 . Any amount received by Bunzzi as Entry Fee for participation in a Contest shall be non-refundable, except in the event that a Contest has been cancelled.<p></p>

                        4.5 . Contests shall be announced by the Bunzzi from time to time in any manner that is convenient to the Firm, and Bunzzi reserves the right to extend the participation and time limit for registration, cancel any Contest, and modify any terms, all with due notice to the Users of the Platform.

                        4.6 Winnings
                        a) Awards for all Contests shall be pre-declared at the time of registration in the specific rules applicable to such Contest. Awards may be in the form of Bunzzi cash bonus .<p></p>

                        b) Users are eligible to withdraw only cash Winnings from his/her Account to his/her respective bank/wallet account when the Winning amount reached 200.<p></p>

                        c) Bunzzi does not issue receipts for entries in the Contests. The entries are accounted within the Account on the Platform. It is the responsibility of the Users to ensure that the entries are accurate and the Winnings are duly credited to his Account.<p></p>

                        d) The rules related to Contest will be shown in the Bunzzi application.<p></p>

                        4.7 Return, Refund and Cancellation<p></p>

                        a) In the event, that there is an error in the Services provided by us, we may refund the Entry Fee, provided the reasons are genuine and proved after investigation by Bunzzi.
                        b) Please read the rules of each Mobile Game and Contest before participating.<p></p>

                        c) We do not cancel registrations once entered, however, in case of exceptional circumstances wherein the fault may lie with the payment gateway or from Our side, We will cancel your participation on request and refund the Entry Fee to You within a reasonable amount of time.<p></p>

                        d) . In case we cancel your participation in any Mobile Game or Contest as a result of this, We will return Your Entry Fee to You within a reasonable amount of time for You to redeem the same by playing other Contests on Bunzzi.<p></p>

                        e) . We will try Our best to create the best user experience for You. If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payments made through a payment gateway, payment refunds will be made to the same account.
                        4.8 . Users must use any money in their Account within 365 days. Bunzzi shall have the right to directly forfeit any such unused amount after 365 (three hundred and sixty five) days. The idle Balance amount lying in your account may be withdrawn only in exceptional circumstances, determined on a case to case basis on the sole discretion of Bunzzi.<p></p>

                        <h6>Termination</h6>

                        <p>5. Your access to the Platform may be terminated if:5.1. You voluntarily uninstall the Platform from Your device;5.2. We may have to terminate Your access to the Platform if:</p>
                        <p>5.2.1 . We are required to do so by law (for example, where the access to and/or provision of the Platform to You becomes, unlawful);
                        </p><p>5.2.2 . The provision of Platform to You, is no longer commercially viable or feasible for Us; or</p>
                        <p>5.2.3 . You are a repeat infringer of this Agreement.</p>
                        <p>5.3 . We may terminate this Agreement at any time, with or without notice and may disable Your access to the Platform and/or bar You from any future use of the Platform.</p>
                        <p>5.4 . You may terminate this Agreement at any time by terminating Your access to the Platform. However, certain obligations of Yours under this Agreement shall continue to prevail even on such termination.</p>
                        <p>5.5 . When this Agreement comes to an end, all of the legal rights, obligations and liabilities that You and Bunzzi have benefited from, been subject to (or which have accrued over time whilst the Agreement has been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and shall continue to apply to such rights, obligations and liabilities indefinitely.</p><p>

                        </p><h6> Referral program</h6>


                        <p>1.Bunzzi may offer referral programs (“Referral Program”) on the Platform to allow all its Users to receive certain rewards in the form of cash Bonus (“Rewards”) for each player that they refer to play on the Platform.</p>

                        <p>2. All active Users of the Platform are eligible to participate in the Referral Program except in cases where an Account has been suspended.</p><p></p><p></p>

                        <p>3. Only ‘successful referrals’ will be counted and eligible to receive the Rewards. A successful referral is an event in which a new User will register on the Platform and complete the sign-up by clicking on the referral link of the referrer or by putting the referrer’s unique referral code and User will get % of the contest entry fees joined by the referee user if he wins the contest.</p><p></p>

                        <p>4. Criteria to use the Rewards – The User will be able to use only a certain percentage of the cumulative Rewards (as permitted by Bunzzi from time to time) reflecting in his/her Account to join any of the upcoming tournaments on the Platform.</p>

                        <p>5. Additional terms applicable to all Referral ProgramsThe nature and value of Rewards to be credited will be based on the Referral Program offered by Bunzzi at any given point in time to an individual User.
                            Referral links will be generated from each User’s Account on the Platform.
                            Rewards and their types are subject to change at Bunzzi discretion without notice.
                            Users found to have been committing fraud will be blocked from the platform.
                            The reward amount/type is based on the applicable reward on the day a user’s referral signs up on the platform.
                            We reserves the right to change rules and regulations without notice.
                            Please contact Customer Support in case of further queries.The nature and value of Rewards to be credited will be based on the Referral Program offered by Bunzzi at any given point in time to an individual User.</p>

                        <p>6. Disqualification from Referral Programs
                            Users will not receive credit for referring themselves by creating multiple Accounts.
                            Bunzzi reserves the right, in its sole discretion, to suspend or disqualify an Account from the Referral Program or nullify the referral Rewards.</p>






                    </div>

                </div>


            </div>


        </>
    )
}

export default RefundAndCanc
