import React, { useEffect } from 'react'

const TdsPolicy = () => {


    // ********* Scroll Upside ********** //
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <>


            <div class="breadcrumbs_aree breadcrumbs_bg" data-bgimg="assets/img/bg/main_bg.png">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumbs_text text-center">
                                <h2>TDS Policy</h2>
                                <ul class="d-flex justify-content-center">
                                    <li><a href="/">Home </a></li>
                                    <li> <span>//</span></li>
                                    <li>  TDS Policy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page_wrapper">
                <div class="container mb-5" >
                    <div class=" align-items justify-content-between">
                        <p>  
                        TDS (Tax Deducted at Source) is the tax amount applicable on your Net Winnings as per the government regulations.<br/>
                        TDS is calculated as 30% of your Net Winnings in a financial year starting from 1st April 2023.<br/>

                        According to the law, TDS will be deducted at 30% of your net winnings at the time of withdrawal.<br/>

                        For reference,<br/>
                        Net winnings* = The difference between Total Withdrawals in a financial year and Total Deposits in the same financial year. A financial year is counted from 1st April to 31st March.
                        <br/>
                        For example, if you have deposited a total of Rs 10,000 in the year, and won the game of having prize pool Rs. 19,000, your Net Winnings will be = (19,000 – 10,000) = Rs 9000 and TDS will be deducted @ 30% on 9000. 30% tax as TDS will be deducted on net winnings at the time of withdrawal. In case of no withdrawal in the entire financial year, TDS will get deducted from net winnings as on 31 March 2024.
                        <br/>
                        *subject to change as per the rules that are to be notified under the Income Tax 1961

                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TdsPolicy
